const localeRu = {
    fHead: "Обращения учредителей",
    founders: {
        may2021: {
            heading: "Обращение учредителей №1: Давайте объединяться",
            intro: "Уважаемые школьники, студенты и просто единомышленники!",
            body: [
                "Для начала хотим поздравить вас с окончанием учебного года! За прошедшее время мы еще сильнее убедились в том, что онлайн-образование никогда не заменит традиционное. Все-таки люди — социальные животные, а значит нам нужно видеть и чувствовать других людей. Вместе с этим пандемия дала шанс всем нам почувствовать, как сложно контролировать себя и учиться чему-то самостоятельно. Тем не менее в нынешних реалиях, когда рынок труда меняется с каждым годом и появляются невиданные ранее профессии — важнее становится навык, умение учиться на протяжении всей жизни.",
                "Во-вторых, хотим отметить круглые даты: 14 апреля прошел год с момента учреждения Фонда, а 17 июня стукнет год с момента официальной регистрации в государственных органах. И в первую очередь, хотим поблагодарить вас всех за поддержку — за распространение наших материалов, за рекомендации друзьям, за добрые комментарии, за ваши пожертвования. Благодаря вам мы горим желанием продолжать, становиться лучше, делать больше.",
                "А делать больше нужно. Ибо другой урок пандемии — без научного мышления в современном обществе никуда. Наличие хотя бы базового фундамента в науке способствует развитию логического и критического мышления, позволяющего отделять достоверную информации от конспирологических теорий. И это не все: нашей стране нужны ученые, нужны высококвалифицированные специалисты с глубокими познаниями естественно-математических дисциплин. Таким образом, все острее становится вопрос популяризации науки и дополнительного образования.",
                "Все это означает, что деятельность ОФ Beyond Curriculum становится и будет становиться еще более актуальной с каждым годом, если не с каждым месяцем. И здесь позвольте обозначить наше видение Фонда: ОФ Beyond Curriculum — это не фиксированный набор проектов, которые мы создали и поддерживаем. ОФ Beyond Curriculum — это не только про выпускников 2016-2019 годов, которые решили поделиться своими знаниями. ОФ Beyond Curriculum — это идея, принцип. Принцип стремления к новому, принцип выхода за рамки школьной программы.",
                "Так, мы хотим обратиться ко всем неравнодушным и амбициозным школьникам и студентам Казахстана и других стран: вы можете воспринимать ОФ Beyond Curriculum не только как источник полезных материалов, но и как площадку, через которую вы можете испытать себя и реализовать свои идеи.",
                "Если вы хотите создать какой-то социальный/некоммерческий проект, вы можете создать его на базе Фонда. Если вы хотите создавать коммерческие проекты, но у вас нет опыта маркетинга или нет понимания юридических норм — вы можете присоединиться к нашей команде и попробовать себя и свои идеи в PR или IR. Иными словами, у вас есть возможность посмотреть на эффективность тех или иных стратегий без риска получить упущенную прибыль. Вы хотите попробовать себя в написании статей о науке для большой аудитории? Попробуйте написать статью в наш Блог. Вы еще не поняли, чем хотите заниматься, но хотите сделать что-то полезное? Присоединяйтесь к нашим текущим проектам. Вы являетесь начинающим веб-разработчиком и хотите пополнить портфолио настоящими проектами? У нас очень много задач, которые вы могли бы взять на себя.",
                "Наш текущий веб-разработчик даже шутит: у нас столько дел, как будто мы — IT-компания.",
                "Мы хотим, чтобы ОФ Beyond Curriculum становился сообществом, в котором инициативные школьники находят единомышленников и начинают свои проекты или получают свой первый опыт. И с нами вы уже можете получить опыт и руководства командами, и проект-менеджмента, и финансового учета, и документооборота, и веб-разработки, и пиара, и написания научно-познавательных статей, и съемки видеоуроков.",
                (<>Мы с нетерпением ждем вас, ваши горящие глаза и безумные идеи. Нашу <a href="mailto:hr@bc-pf.org">почту</a> вы знаете. 🙂</>)
            ],
            confirmed: "Утверждено: №01-01/07 от 28 мая 2021 г."
        }
    }
}

export default localeRu