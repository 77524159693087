import React, { useState, useEffect } from "react";
import Navbar from "./Components/Navbar";
import "./App.css";
import locale from "./locale.jsx";
import { LangProvider, langsMap } from "./Utils/Lang";
import { WidthProvider } from "./Utils/Width";
import { BrowserRouter as Router, Route, Redirect, Switch, Link, useLocation } from "react-router-dom";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import Index from "./Pages/Index";
import Footer from "./Components/Footer";
import Communities from "./Components/Communities";
import ScrollToTop from "./Utils/ScrollToTop";
import NotFound from "./Pages/NotFound";
import About from "./Pages/About";
import Team from "./Pages/Team";
import Donate from "./Pages/Donate";
import Merch from "./Pages/Merch";
import Lampa from "./Pages/Lampa";
import Contacts from "./Pages/Contacts";
import Post from "./Pages/Post";
import clsx from "clsx";
import Olympiads from "./Pages/Olympiads";
import PersonalData from "./Pages/PersonalData";
import Documents from "./Pages/Documents";
import Verify from "./Pages/Verify";
import Reports from "./Pages/Reports";
import Modal from "./Components/Modal";
import Input from "./Components/Input";
import Axios from "axios";
import Jobs from "./Pages/Jobs";
import Letters from "./Pages/Letters";
import Letter from "./Pages/Letter";
import Crowdfund from "./Pages/Crowdfund";

const findGetParameter = (parameterName) => {
  let result = null,
    tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach((item) => {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
};

const getLang = () => {
  const lang = localStorage.getItem("lang")
  return ["ru", "en", "kz"].indexOf(lang) > -1 ? lang : null
}

const App = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false)
  const [modal, setModal] = useState(false)
  const [lang, updateLang] = useState(langsMap[findGetParameter("lang")] || getLang() || "ru");
  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const setLang = (lang) => {
    localStorage.setItem("lang", lang)
    updateLang(lang);
  }

  useEffect(() => {
    lang === "kz" ? document.body.classList.add("kz") : document.body.classList.remove("kz");
  }, [lang])

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    document.body.classList.add("loaded");
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  const subscribe = () => {
    setLoading(true)
    Axios({
      method: 'GET',
      url: '/subscribe.php',
      params: {
        email
      }
    }).then(res => {
      setLoading(false)
      if (res.data && !res.data.error_count) {
        setDone(true);
        setTimeout(() => {
          setDone(false);
          setEmail("");
          setModal(false);
        }, 2000)
      } else {
        setError(true);
        setLoading(false);
      }
    }).catch(err => {
      setError(true);
      setLoading(false);
    })
  }

  const instance = createInstance({
    urlBase: 'https://analytics.bc-pf.org/',
    siteId: 9,
    // userId: 'UID76903202', // optional, default value: `undefined`.
    // trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
    // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
      active: true, // optional, default value: true
      // seconds: 10 // optional, default value: `15
    },
    // linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
      // any valid matomo configuration, all below are optional
      disableCookies: true,
      setSecureCookie: true,
      // setRequestMethod: 'POST'
    }
  })

  const l = locale[lang];

  return (
    <MatomoProvider value={instance}>
      <LangProvider value={lang}>
        <WidthProvider value={width}>
          <Router>
            <Navbar setModal={setModal} setLang={setLang} />
            <ScrollToTop>
              <Switch>
                <Route path="/" exact component={Index} />
                <Route path="/communities" exact component={Communities} />
                <Route path="/about" exact component={About} />
                <Route path="/olympiads" exact component={Olympiads} />
                <Route path="/personaldata" exact component={PersonalData} />
                <Route path="/members" exact component={Team} />
                <Route path="/donate" exact component={Donate} />
                {/* <Route path="/merch190621" exact component={Merch} /> */}
                <Route path="/lampa" exact component={Lampa} />
                <Route path="/contacts" exact component={Contacts} />
                <Route path="/letters/" exact component={Letters} />
                <Route path="/letters/:author/:letter" exact component={Letter} />
                <Route path="/community/:community" exact component={Post} />
                <Route path="/documents" exact component={Documents} />
                <Route path="/verify" exact component={Verify} />
                <Route path="/reports" exact component={Reports} />
                {/* <Route path="/crowdfund" exact component={Crowdfund} /> */}
                {/* <Route path="/jobs" exact component={Jobs} /> */}
                {/* <Route path="/jobs"><Redirect to="https://ask.bc-pf.org/t/kak-popast-v-komandu-beyond-curriculum/4500" /></Route> */}
                <Route path='/jobs' component={() => { 
                    window.location.href = 'https://ask.bc-pf.org/t/kak-popast-v-komandu-beyond-curriculum/4500'; 
                    return null;
                }}/>
                <Route path="*" component={NotFound} />
              </Switch>
            </ScrollToTop>
            <Float l={l} />
            <Footer />
            <Modal open={modal} close={() => setModal(false)} title={l.subscribe}>
              <form onSubmit={e => {
                e.preventDefault();
                subscribe()
              }} className={`subscribe ${done ? "done" : ""}`}>
                <p>{l.sub}</p>
                <Input value={email} placeholder="E-mail" type="email" onChange={e => setEmail(e.target.value)} />
                <button disabled={loading}>{l.subscribe}</button>
              </form>
            </Modal>
            <Modal open={error} close={() => setError(false)} title={l.contacts.error}>
              <p>{l.contacts.errorText}</p>
            </Modal>
          </Router>
        </WidthProvider>
      </LangProvider>
    </MatomoProvider>
  );
};


const Float = ({ l }) => {
  const { pathname } = useLocation();
  return (
    <Link to="/donate" className={clsx({
      float: true,
      active: pathname !== "/donate"
    })}>
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
      <span>{l.donate.pay}</span>
    </Link>
  )
}

export default App;
