import React, { useContext, useState, useEffect } from "react";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Axios from "axios";
import Plx from "react-plx";
import moment from "moment";
import forbes from "../Assets/forbes.svg"
import bilim from "../Assets/bilim.svg"
import daryn from "../Assets/daryn.svg"
import kazinform from "../Assets/kazinform.svg"
import weproject from "../Assets/weprojectfull.svg"
import steppe from "../Assets/steppe.svg"
import "moment/locale/ru";
import "moment/locale/kk";
import "moment/locale/en-gb";

import classes from "./Index.module.css";
import Container from "../Components/Container";
import LangContext from "../Utils/Lang";
import WidthContext from "../Utils/Width";
import locale from "../locale.jsx";
import hero from "../Assets/hero.jpg";
import Projects from "../Components/Projects";
import Communities from "../Components/Communities";
import Loader from "../Components/Loader";
import Section from "../Components/Section";

import idf from "../Assets/idf.svg";
import connected from "../Assets/connected.svg";
import dos from "../Assets/dos.svg";
import biomolecula from "../Assets/biomolecula.svg";
import nobel from "../Assets/nobel.svg";

const plx = {
  data: [
    {
      start: "self",
      duration: "100vh",
      properties: [
        {
          startValue: 200,
          endValue: 0,
          property: "translateY",
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ],
  beyond: [
    {
      start: "self",
      duration: 500,
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "scaleX",
        },
      ],
    },
  ],
};

const exlogos = [
  {
    l: daryn,
    t: "РНПЦ Дарын",
    url: "https://daryn.kz/"
  },
]

const logos = [
  {
    l: biomolecula,
    url: "https://biomolecula.ru/",
    t: "Биомолекула"
  },
  {
    l: idf,
    url: "https://inpolicy.net/",
    t: "Inclusive Development Foundation"
  },
  {
    l: nobel,
    url: "https://nobel-fest.inpolicy.net/",
    t: "Central Asia Nobel Fest"
  },
  {
    l: connected,
    url: "https://connect-ed.kz/",
    t: "Connect-ed"
  },
  {
    l: dos,
    t: "ДОС.kz"
  }
]

const Index = () => {
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView()
  }, [])
  const lang = useContext(LangContext);
  const width = useContext(WidthContext);
  const l = locale[lang];
  const [posts, setPosts] = useState({
    isFetching: true,
    items: [],
  });
  const filterValue = (lang === "kz" ? 'tag:qazaqsha' : 'featured:true')
  useEffect(() => {
    Axios({
      url: "https://blog.bc-pf.org/ghost/api/content/posts/",//https://blog.bc-pf.org/ghost/api/v3/content/posts/",
      params: {
        key: "ce31f644352400ceb949fe4389",
        fields: "title,url,feature_image,published_at,author",
        include: "authors",
        filter: filterValue,
        limit: 6,
      },
    }).then((res) => {
      if (res.data && res.data.posts.length) {
        setPosts({
          isFetching: false,
          items: res.data.posts,
        });
      }
    });
  }, []);
  moment.locale(lang === "kz" ? "kk" : lang === "en" ? "en-gb" : "ru");
  return (
    <div className={classes.Index}>
      <div className={classes.hero}>
        <Container className={classes.heroContainer}>
          <h1>{l.home.t1}</h1>
          <img src={hero} alt={l.home.t1} />
          <div className={classes.caption}>Photo by Joshua Earle on Unsplash</div>
          <div className={classes.text}>{l.home.p1}</div>
        </Container>
      </div>
      <div className={classes.pun}>
        <Container>
          <h2>{l.home.t2}</h2>
          <div className={classes.text}>
            <span>{l.home.p2}</span>
            <span className={classes.beyond}>
              {l.home.p2_1}
              <Plx parallaxData={plx.beyond} />
            </span>
            <span>{l.home.p2_2}</span>
          </div>
        </Container>
      </div>
      <div className={classes.how}>
        <Container>
          <h2>{l.home.t3}</h2>
          <p>{l.home.p3}</p>
          {width > 960 ? (
            <Plx parallaxData={plx.data} className={classes.image} ><div className={classes.caption}>Photo by Philippe Bout on Unsplash</div></Plx>
          ) : (
              <div className={classes.image} ><div className={classes.caption}>Photo by Philippe Bout on Unsplash</div></div>
            )}
        </Container>
      </div>
      <Projects />
      {/* <Communities /> */}
      <div className={classes.posts}>
        <Container>
          <h2>{l.home.blog}</h2>
          <div className={classes.postsWrap}>
            {posts.isFetching ? (
              <Loader />
            ) : (
                posts.items.map((el) => <Post post={el} key={el.url} />)
              )}
          </div>
        </Container>
      </div>
      <Section title={l.partners}>
        <div className={classes.logos}>
          {logos.map((el) => {
            const img = <img src={el.l} alt={el.t} />
            return el.url ? <a href={el.url} target="_blank" rel="noopener noreferrer">
              {img}
            </a> : <div>{img}</div>
          })}
        </div>
      </Section>
      <Section title={l.media}>
        <div className={classes.mediaLinks}>
          <a target="_blank" rel="noopener noreferrer" href="https://forbes.kz/process/education/kak_vyiyti_za_ramki_shkolnogo_obrazovaniya/">
            <img src={forbes} alt="Forbes" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://5ea5b30d-4ecd-42be-b4dc-905fadc92142.filesusr.com/ugd/c11ddf_bdd69f1395c74e07bb28b9e1db4a601a.pdf">
            <img src={bilim} alt="Bilim.expert" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.inform.kz/ru/stolichnyy-student-zapustil-besplatnyy-onlayn-kurs-po-robototehnike_a3776398">
            <img src={kazinform} alt="Kazinform" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://weproject.media/articles/detail/zapushchen-proekt-beyondwiki-kazakhstanskoy-shkolnoy-vikipedii/">
            <img src={weproject} alt="WEproject" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://the-steppe.com/razvitie/10-youtube-kanalov-dlya-teh-kto-zabotitsya-o-samorazvitii">
            <img src={steppe} alt="TheSteppe" />
          </a>
        </div>
      </Section>
    </div>
  );
};

const Post = ({ post }) => (
  <a href={post.url} className={classes.post}>
    <div className={classes.postImageWrapper}>
      <img
        alt={post.title}
        className={classes.postImage}
        src={post.feature_image}
      />
    </div>
    <div className={classes.postContent}>
      <header>
        <h2>{post.title}</h2>
      </header>
      <footer>
        <div className={classes.author}>
          <div
            style={{ backgroundImage: `url(${post.authors[0].profile_image})` }}
            className={classes.authorPhoto}
          />
          <div className={classes.authorName}>{post.authors[0].name}</div>
        </div>
        <div className={classes.postDate}>
          {moment(post.published_at).fromNow()}
        </div>
      </footer>
    </div>
  </a>
);

export default Index;