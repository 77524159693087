import React from 'react';
import classes from './Container.module.css'
import clsx from 'clsx';

const Container = ({children, style, className}) => {
  return (
    <div style={style} className={clsx(classes.Container, className)}>{children}</div>
  );
}

export default Container;