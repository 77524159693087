import { Link } from "react-router-dom";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import classes from './Letters.module.css'
import locale from "../locales/letterLocale.jsx";
import LangContext from "../Utils/Lang";
import React, { useContext } from "react"; 
import Container from '../Components/Container';

const links = [
    "/founders/may2021"
]

const Letters = () => {
    const { trackPageView } = useMatomo()
    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])
    const lang = useContext(LangContext);
    const l = locale[lang];
    return (
        <Container>
            <div className={classes.container}>
                <h1>{l.fHead}</h1>
                <ul className={classes.flexbox}>
                    {links ? links.map((ltr, i) => (
                        <li className={classes.link}><Link to={`/letters${ltr}`}>{l[ltr.split("/")[1]][ltr.split("/")[2]].heading} ({l[ltr.split("/")[1]][ltr.split("/")[2]].confirmed.split("№")[1].split(" ").slice(2, 5).join(" ")})</Link></li>
                    )) : null}
                </ul>
                
            </div>
        </Container>
    )
}

export default Letters