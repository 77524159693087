import React from 'react';
import classes from './FileFormalTable.module.css';
import locale from "../locale.jsx";

const getByLang = (data, lang) => {
    return typeof data === "string" ? data : data[lang]
}

const getFileName = (url) => {
    const path = url.split("/");
    return path[path.length - 1]
}

const FileFormalTable = ({ items = [], lang }) => {
    const l = locale[lang];
    return (
        <div className={classes.FileTable}>
            <table>
                <thead>
                    <tr>
                        <th>{l.component.code}</th>
                        <th>{l.component.name}</th>
                        <th>{l.component.place}</th>
                        <th>{l.component.grade}</th>
                        <th>{l.component.date}</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((el, i) => (
                        <tr>
                            {el.url ? 
                            <>
                                <td><a className={el.url ? classes.link : ""} href={el.url ? el.url : ""}>{el.title}</a></td>
                                <td><a className={el.url ? classes.link : ""} href={el.url ? el.url : ""}>{el.name}</a></td>
                                <td>{el.place ? el.place : null}</td>
                                <td>{el.grade ? el.grade : null}</td>
                                <td>{el.date ? el.date : null}</td>
                            </> 
                            : <> 
                                <td>{el.title}</td>
                                <td>{el.name}</td>
                                <td>{el.place ? el.place : null}</td>
                                <td>{el.grade ? el.grade : null}</td>
                                <td>{el.date ? el.date : null}</td>
                            </>}
                            
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default FileFormalTable;