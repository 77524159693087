import React, {useContext, useState, useEffect} from "react";
import Axios from "axios";

import LangContext from "../Utils/Lang";
import classes from "./Projects.module.css";
import Container from "./Container";
import locale from "../locale.jsx";


const Projects = () => {
  const lang = useContext(LangContext);
  const l = locale[lang];
  const [items, setItems] = useState([]);
  useEffect(() => {
    Axios({
      url: "/data/projects.json",
      params: {
        timestamp: (new Date()) * 1
      }
    }).then((res) => {
      if (res.data) {
        setItems(res.data);
      }
    });
  }, []);
  return (
    <div className={classes.Projects} id="projects">
      <Container>
        <h2>{l.home.projects.h2}</h2>
        <div className={classes.items}>
          {items.map((el, i) => (
            <a
              className={classes.item}
              key={i}
              href={el.url[lang] ? el.url[lang] : el.url}
            >
              <div className={classes.itemInfo}>
                <div className={classes.itemInfoFirst}>
                  <h3><a href={el.url[lang] ? el.url[lang] : el.url}>{el.title[lang]}</a></h3>
                  <div className={el.statusBool ? classes.itemActive : el.statusBool === null ? classes.itemFrozen : classes.itemNull}>
                    <span>{el.statusBool ? l.home.projects.active : el.statusBool === null ? l.home.projects.inactive : l.home.projects.null}</span>
                  </div>
                </div>
                <div className={classes.itemInfoSecond}>
                  <p>{el.label[lang]}</p>
                  <p><span className={classes.dateInfo}>{l.home.projects.p}:</span> {el.dateStart[lang]}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Projects;
