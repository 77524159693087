import localeEn from "./localeEn";
import localeRu from "./localeRu";
import localeKz from "./localeKz";

const locale = {
  ru: localeRu,
  en: localeEn,
  kz: localeKz,
};

export default locale;
