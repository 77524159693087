import React, { useContext, useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import classes from './Contacts.module.css'
import Section from '../Components/Section';
import Input from '../Components/Input';
import locale from "../locale.jsx";
import LangContext from "../Utils/Lang";
import Loader from '../Components/Loader';
import Modal from '../Components/Modal';
import Axios from 'axios';
import key from "../Assets/key.svg";
import documentolog from "../Assets/documentolog.png";

const Contacts = () => {
    const { trackPageView } = useMatomo()
    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])
    const lang = useContext(LangContext);
    const l = locale[lang];
    const [modal, setModal] = useState({
        title: "",
        open: false,
        text: ""
    });
    const [sending, setSending] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [text, setText] = useState("");
    const send = () => {
        setSending(true);
        const data = new FormData();
        data.append('name', name);
        data.append('email', email);
        data.append('text', text);
        Axios({
            url: "/req.php",
            method: "POST",
            data: data
        }).then((res) => {
            if (res.data && res.data.success) {
                setSending(false);
                setModal({
                    title: l.contacts.sent,
                    open: true,
                    text: l.contacts.sentText
                })
                setName("");
                setEmail("");
                setText("");
            } else {
                setSending(false);
                setModal({
                    title: l.contacts.error,
                    open: true,
                    text: l.contacts.errorText
                })
                setName("");
                setEmail("");
                setText("");
            }
        }).catch(err => {
            setSending(false);
            setModal({
                title: l.contacts.error,
                open: true,
                text: l.contacts.errorText
            })
        })
    }
    return (
        <div className={classes.Contacts}>
            <Section align="left" title={l.contacts.t1}>
                {sending ? <Loader /> : <form onSubmit={e => {
                    e.preventDefault();
                    send();
                }}>
                    <div className={classes.field}>
                        <Input required type="text" placeholder={l.contacts.name} value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className={classes.field}>
                        <Input required type="email" placeholder={l.contacts.email} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className={classes.field}>
                        <Input required type="textarea" placeholder={l.contacts.text} value={text} onChange={(e) => setText(e.target.value)} />
                    </div>
                    <button>{l.contacts.send}</button>
                </form>}
                <div className={classes.text}>{l.contacts.p1}</div>
            </Section>
            <Section>
                <a href="/BeyondCurriculum.asc" download className={classes.key}>
                    <img src={key} alt="Ключ" />
                    <div className={classes.keyText}>
                        <div className={classes.keyTitle}>PGP Key</div>
                        <div className={classes.keySubtitle}>BeyondCurriculum.asc</div>
                    </div>
                </a>
                <p>{l.contacts.secure}</p>
            </Section>
            <Section>
                <div className={classes.docs}>
                    <img src={documentolog} alt="Documentolog" />
                    <p>{l.contacts.docs}</p>
                </div>
            </Section>
            <Modal open={modal.open} close={() => setModal({ ...modal, open: false })} title={modal.title}>
                {modal.text}
            </Modal>
        </div>
    );
}

export default Contacts;