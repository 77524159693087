const localeKk = {
    fHead: "Құрылтайшылардың жолдауы",
    founders: {
        may2021: {
            heading: "Құрылтайшылардың №1 жолдауы: Бәріміз бірігейік",
            intro: "Құрметті мектеп оқушылары, студенттер және пікірлестер!",
            body: [
                "Алдымен сіздерді оқу жылының аяқталуымен құттықтағымыз келеді! Осы жылдар ішінде біз онлайн-білім ешқашан дәстүрлі білімнің орнын баса алмайтындығына тағы да көз жеткіздік. Адамдар - бұл әлеуметтік жануарлар, біз басқа адамдарды көріп, сезінуіміз керек. Сонымен қатар, пандемия барлығымызға өзімізді басқарудың және өздігінен бір нәрсе үйренудің қаншалықты қиын екенін сезінуге мүмкіндік берді. Соған қарамастан, қазіргі таңда, яғни жыл сайын еңбек нарығы өзгерген кезде, жыл сайын бұрын сонды байқалмаған мамандықтар пайда болған кезде өмір бойы үйрену дағдысы мен қабілеті маңызды болып келе жатыр.",
                "Екіншіден, біз маңызды күндерді атап өткіміз келеді: 14 сәуірде Қордың құрылғанына бір жыл, ал 17 маусымда мемлекеттік органдарда ресми тіркелген сәттен бастап бір жыл өтті. Және бірінші кезекте, біз сіздердің барлықтарыңызға қолдау көрсеткендеріңіз үшін алғыс айтамыз: біздің материалдарды таратқаны үшін, достарыңызға кеңес бергендеріңіз үшін, жылы пікірлеріңіз үшін, қайырымдылықтарыңыз үшін. Сіздердің арқаларыңызда бізде жалғастыруға, жақсаруға, көп нәрсені жасауға үлкен ниет бар.",
                "Әл де көп нәрсені жасау керек. Пандемияның тағы бір сабағы — ғылыми ойлаусыз жаңа қоғамда бірде-бір орын табылмайды. Ғылымдағы кем дегенде білім іргетасының болуы сенімді ақпаратты қастандық теорияларынан ажырата алуға мүмкіндік беретін логикалық және сыни ойлауды дамытуға ықпал етеді. Одан бөлек, біздің елімізге жаратылыстану-математикалық пәндерді терең білетін ғалымдар, жоғары білікті мамандар қажет. Осылайша ғылым мен қосымша білім беруді кеңінен насихаттау мәселесі күн өткен сайын өзекті бола түсуде.",
                "Мұның бәрі Beyond Curriculum ҚҚ қызметі ай сайын болмаса да, жыл сайын өзекті бола түсетіндігін білдіреді. Осы ретте Қор туралы біздің  ой саптауымызды атап өтуге рұқсат етіңіз: Beyond Curriculum ҚҚ - бұл біз құрған және қолдайтын жобалардың жиынтығы емес. Beyond Curriculum ҚҚ тек өз білімімен бөлісуге шешім қабылдаған 2016-2019 жж. түлектері туралы ғана емес. Beyond Curriculum ҚҚ - бұл идея, бұл қағида. Жаңа нәрсеге ұмтылу принципі, мектеп бағдарламасынан тыс шығу принципі.",
                "Сондықтан біз барлық құштарлығы мол және өршіл мектеп оқушылары мен студенттеріне (тек қана емес) жүгінгіміз келеді: сіздер Beyond Curriculum ҚҚ-ын тек пайдалы материалдардың көзі ретінде ғана емес, сонымен қатар, өзідеріңіздің білімдеріңіз бен идеяларыңызды сынай алатын платформа ретінде де қабылдай аласыздар. ",
                "Егер сіздер қандай да бір әлеуметтік / коммерциялық емес жоба жасағыңыз келсе, оны қор негізінде жасай аласыз. Егер сіздер коммерциялық жобалар жасағыңыз келсе, бірақ сіздердің маркетингтік тәжірибелеріңіз болмаса немесе заң нормаларын түсінбейтін болсаңыздар сіздер біздің командаға қосылып, өздеріңізді және идеяларыңызды PR немесе IR-де байқап көре аласыз. Басқаша айтқанда, сіздерде шығынға батпай белгілі бір стратегиялардың тиімділігін іс жүзінде сынап көру мүмкіндігі бар. Сіздер жалпы аудиторияға арналған ғылыми мақалалар жазуда өздеріңізді сынап көргілеріңіз келе ме? Онда біздің блогта мақала жазып, тырысып көріңіздер. Не істегілеріңіз келетінін, бірақ пайдалы нәрсе жасағыларыңыз келетінін әлі де білмейсіздер ме? Біздің жүзеге асырылып жатқан жобаларға қосылыңыздар. Сіздер бастаушы веб-әзірлеуші болсаңыздар  және портфолиоларыңызды нақты жобалармен кеңейтуді қалайсыздар ма? Бізде сізге жүктейтін әлі де жеткілікті тапсырмалар бар.",
                "Біздің қазіргі веб-әзірлеушіміз, тіпті, кейде былай әзілдеп қояды: \"Бізде неткен көп тапсырма тура бір біз IT-компаниялар секілдіміз\".",
                "МБіз Beyond Curriculum ҚҚ өзін-өзі ынталандыратын студенттер пікірлес адамдарды тауып, өз жобаларын бастайтын немесе алғашқы тәжірибесін алатын қоғамға айналғанын қалаймыз. Бізде сіздер тәжірибе алып және командалардың көшбасшылық тізгінің жүргізіп , сондай-ақ, жобалық менеджмент, қаржылық есеп, жұмыс процесі, веб-дамыту, PR, ғылыми мақалалар жазу және бейне оқулықтар түсіре аласыздар. Біз сіздерді, сіздердің жанып тұрған көздеріңізді және ессіз идеяларыңызды күтеміз.",
                (<>Сіздер біздің <a href="mailto:hr@bc-pf.org">электрондық поштамызды</a> білесіздер 🙂</>)
            ],
            confirmed: "Бекітілді: №01-01/07 Мамырдың 28, 2021 ж."
        }
    }
}

export default localeKk