import React from "react";
import classes from "./Section.module.css";
import Container from "./Container";
import clsx from "clsx";

const Section = ({ title, children, className, style, align = "center", big, container = true }) => {
  return (
    <div
      style={{
        ...style,
        textAlign: align,
      }}
      className={clsx(classes.Section, className)}
    >
      {container ? (<Container>
        <h2 className={clsx({
          [classes.big]: big
        })}>{title}</h2>
        {children}
      </Container>) : (<>
        <Container>
          <h2 className={clsx({
            [classes.big]: big
          })}>{title}</h2>
        </Container>
        {children}</>
        )}
    </div>
  );
};

export default Section;
