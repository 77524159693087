import classes from "./Plotly.module.css";
import { useContext } from "react";
import LangContext from "../Utils/Lang";
import locale from "../locale.jsx";


export default function Plotly( {fname, height} ) {
    const lang = useContext(LangContext);
    const l = locale[lang];
    return (
        <>
            <iframe className={classes.graph} src={`/graphs/${fname}.html`} width="100%" height={`${height}`}></iframe>
            <p className={classes.warning}>{l.reports.warn}</p>
        </>
    )
}
