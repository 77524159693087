import React from "react";
import classes from "./Input.module.css";

const Input = ({ value, onChange, placeholder, type = "text", onClick, required }) => (
    <div onClick={() => onClick && onClick()} className={classes.Input}>
        {type === "textarea" ? <textarea required={required} placeholder=" " value={value} onChange={onChange} /> : <input required={required} type={type} placeholder=" " value={value} onChange={onChange} />}
        <label>{placeholder}</label>
    </div>
)

export default Input