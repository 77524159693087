import React, { useContext } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import classes from './Letter.module.css'
import locale from "../locales/letterLocale.jsx";
import LangContext from "../Utils/Lang";
import Container from '../Components/Container';
import { Redirect } from 'react-router-dom';

const founders = [
    "/team/Member1.jpg",
    "/team/pak.jpg"
]

const trustees = [
    "/team/basharova.jpg",
    "/team/polyanskikh.jpg",
    "/team/sossin.jpg",
    "/team/zagribelnyy.jpg",
]

const founderNames = {
        "ru": ["Антон Моргунов,", "Георгий Пак"],
        "kz": ["Антон Моргунов,", "Георгий Пак"],
        "en": ["Anton Morgunov,", "Georgiy Pak"],
    }

const trusteeNames = {
        "ru": ["Башарова К.К., Полянских С.С., Сосин В.Н., Загрибельный Б.А."],
        "kz": ["Башарова К.К., Полянских С.С., Сосин В.Н., Загрибельный Б.А."],
        "en": ["K. Basharova", "S. Polyanskikh", "V. Sossin", "B. Zagribelnyy"],
    }

const Letter = ({ match }) => {
    const { trackPageView } = useMatomo()
    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])
    const lang = useContext(LangContext);
    const l = locale[lang];
    const post = match.params.author ? l[match.params.author][match.params.letter] : null
    const authorPics = match.params.author === "founders" ? founders : trustees
    const authorNames = match.params.author === "founders" ? founderNames : trusteeNames
    console.log(authorNames, lang)
    return (
        <div>
            <Container>
                {post ? (
                    <article>
                        <h1 className={classes.heading}>{post.heading}</h1>
                        <div className={classes.authorContainer}>
                            <div className={classes.authors}>
                                {authorPics ? authorPics.map((src, i) => (
                                    <img src={src} key={i} className={classes.authorPic}></img>
                                )) : null}
                            </div>
                            <div className={classes.details}>
                                {authorNames ? authorNames[lang].map((name, j) => (
                                    <span key={j} className={classes.names}>{name}</span>
                                )) : null}
                            </div>
                        </div>
                        <div className={classes.stamp}>{post.confirmed}</div>


                        <h3 className={classes.intro}>{post.intro}</h3>
                        <div className={classes.content}>
                            {post.body ? post.body.map((paragraph, i) => (
                                <p key={i} className={classes.paragraph}>{paragraph}</p>
                            )) : null}
                        </div>
                    </article>
                ) : <Redirect to="/404" />}
            </Container>
        </div>
    );
}

export default Letter;