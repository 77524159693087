import React from "react";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import classes from "./NotFound.module.css";
import Container from "../Components/Container";

const NotFound = () => {
  const { trackPageView } = useMatomo()
    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])
  return (
    <div className={classes.NotFound}>
      <Container className={classes.Container}>
        <h2>Страница не найдена</h2>
        <div className={classes.error}>404</div>
      </Container>
    </div>
  );
};

export default NotFound;
