import React, { useContext } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import classes from './Post.module.css'
import locale from "../locale.jsx";
import LangContext from "../Utils/Lang";
import Container from '../Components/Container';
import { Redirect } from 'react-router-dom';

const Post = ({ match }) => {
    const { trackPageView } = useMatomo()
    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])
    const lang = useContext(LangContext);
    const l = locale[lang];
    const post = match.params.community ? l.posts[match.params.community] : null
    return (
        <div className={classes.Post}>
            <Container>
                {post ? (
                    <article>
                        <div style={{ backgroundImage: `url(${post.img})` }} className={classes.header}>
                            <h1 className={classes.title}>{post.title}</h1>
                        </div>
                        <div className={classes.content}>
                            {post.post}
                        </div>
                        <a href={post.url} target="_blank" rel="noopener noreferrer" className={classes.button}>
                            {l.link}
                        </a>
                    </article>
                ) : <Redirect to="/404" />}
            </Container>
        </div>
    );
}

export default Post;