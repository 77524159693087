import React, { useContext, useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import locale from "../locale.jsx";
import LangContext from "../Utils/Lang";
import Section from "../Components/Section";
import classes from './Documents.module.css';
import FileTable from '../Components/FileTable';
import Loader from '../Components/Loader';
import Axios from 'axios';


const Documents = () => {
    const { trackPageView } = useMatomo()
    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])
    const lang = useContext(LangContext);
    const l = locale[lang];
    const [docs, setDocs] = useState({});
    useEffect(() => {
        Axios({
            url: "/data/docs.json",
            params: {
                timestamp: (new Date()) * 1
            }
        }).then((res) => {
            if (res && res.data) {
                setDocs(res.data)
            }
        })
    }, [])
    return (
        <div className={classes.Documents}>
            <Section align="left" title={l.regulations}>
                {docs.regulations ? <FileTable lang={lang} items={docs.regulations} /> : <Loader />}
            </Section>
            <Section align="left" title={l.other}>
                {docs.other ? <FileTable lang={lang} items={docs.other} /> : <Loader />}
            </Section>
        </div>
    );
};

export default Documents;