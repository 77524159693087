import React from 'react';
import classes from './FileTable.module.css';

const getByLang = (data, lang) => {
    return typeof data === "string" ? data : data[lang]
}

const getFileName = (url) => {
    const path = url.split("/");
    return path[path.length - 1]
}

const FileTable = ({ items = [], lang }) => {
    return (
        <div className={classes.FileTable}>
            {items.map((el, i) => (
                <a target="_blank" rel="noopener noreferrer" key={i} href={getByLang(el.url, lang)} className={classes.file}>
                    <div className={classes.fileIcon}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="butt" strokeLinejoin="arcs"><path d="M13 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V9l-7-7z" /><path d="M13 3v6h6" /></svg></div>
                    <div className={classes.fileMeta}>
                        <div className={classes.fileName}>{getByLang(el.title, lang)}</div>
                        <div className={classes.fileUrl}>{getFileName(getByLang(el.url, lang))}</div>
                    </div>
                </a>
            ))}
        </div>
    );
};

export default FileTable;