import localeRu from "./letterLocaleRu";
import localeEn from "./letterLocaleEn";
import localeKk from "./letterLocaleKk";

const letterLocale = {
  ru: localeRu,
  en: localeEn,
  kz: localeKk,
};

export default letterLocale;
