import React from "react";
import classes from "./Logo.module.css";
import clsx from "clsx";

const Logo = ({ animated, size = 64, className, duration = 1000, style = {} }) => {
  return (
    <div style={{
      "--size": size + "px",
      "--duration1": (duration / 3) + "ms",
      "--duration2": (duration * 2 / 3) + "ms",
      "--duration3": (duration * 0.166) + "ms",
      "--duration4": (duration * 0.832) + "ms",
      ...style
    }} className={clsx(classes.Logo, className)}>
      <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M32 32h13.5A13.5 13.5 0 1032 18.5v27A13.5 13.5 0 1118.5 32H32z"
            stroke="currentColor"
            strokeWidth="3"
            fill="none"
            className={animated ? classes.bEjIzBBZ_1 : null}
          ></path>
          <path
            d="M32 32v13.5A13.5 13.5 0 1118.5 32H32z"
            fill="currentColor"
            className={animated ? classes.bEjIzBBZ_2 : null}
          ></path>
        </g>
      </svg>
      <div
        className={clsx({
          [classes.title]: true,
          [classes.animated]: animated,
        })}
      >
        <span>beyond</span>
        <span>curriculum</span>
      </div>
    </div>
  );
};

export default Logo;
