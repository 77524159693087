import React, { useContext, useEffect, useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import locale from "../locale.jsx";
import clsx from "clsx"
import LangContext from "../Utils/Lang";
import Section from '../Components/Section';
import Loader from '../Components/Loader';
import FileTable from "../Components/FileTable";
import Plotly from "../Components/Plotly"
import classes from './Reports.module.css';
import Axios from "axios";


const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        const negativeSign = amount < 0 ? "-" : "";
        let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
        let j = i.length > 3 ? i.length % 3 : 0;
        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount
                ? decimal +
                Math.abs(amount - i)
                    .toFixed(decimalCount)
                    .slice(2)
                : "")
        );
    } catch (e) {
        console.log(e);
    }
};

const Acts = () => {
    const { trackPageView } = useMatomo()
    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])

    const selections = [
        {
            name: {
                'ru': "Все сайты",
                'kz': 'Барлық сайттар',
                'en': 'All sites'
            },
            value: "sites",
        },
        {
            name: {
                'ru': "Форум Спроси",
                'kz': 'Сұра форумы',
                'en': 'Ask forum'
            },
            value: "ask"
        }
    ]
    const [selection, setSelection] = useState("sites")
    const dataTypes = [
        {
            name: {
                'ru': "Кол-во посещений",
                'kz': 'Кірулер саны',
                'en': 'Visits'
            },
            value: "visits"
        },
        {
            name: {
                'ru': "Кол-во просмотров",
                'kz': 'Көрулер саны',
                'en': 'Pageviews'
            },
            value: "views"
        },
        {
            name: {
                'ru': "Показатель отказов",
                'kz': 'Бас тартудың көрсеткіші',
                'en': 'Bounce rate'
            },
            value: "bounce"
        },
        {
            name: {
                'ru': "Длительность сессии",
                'kz': 'Сессиялар ұзақтығы',
                'en': 'Session duration'
            },
            value: "duration"
        },
    ]
    const [dataType, setDataType] = useState("visits")

    const sources = [
        {
            name: "All sites",
            value: "sources-bar"
        }
    ]
    const [source, setSource] = useState("sources-bar")

    const currencies = [
        {
            name: 'KZT',
            value: 'KZT'
        },
        {
            name: 'USD',
            value: 'USD'
        }
    ]

    const years = ['2020', '2021', '2022', '2023', '2024']
    const [year, setYear] = useState('2024')

    const [curr, setCurr] = useState("KZT")
    const [data, setData] = useState(null);
    useEffect(() => {
        Axios({
            method: "GET",
            url: "/data/payments.json",
            params: {
                timestamp: (new Date()) * 1
            }
        }).then(res => {
            if (res && res.data) {
                setData(res.data)
            }
        });
    }, [])
    const lang = useContext(LangContext);
    const l = locale[lang];
    const [docs, setDocs] = useState({});
    useEffect(() => {
        Axios({
            url: "/data/reports.json",
            params: {
                timestamp: (new Date()) * 1
            }
        }).then((res) => {
            if (res && res.data) {
                setDocs(res.data)
            }
        })
    }, [])
    return (
        <div>
            <Section align="left" title={l.reports.t1}>
                <p>{l.reports.p1}</p>
                {docs.activity ? <FileTable lang={lang} items={docs.activity} /> : <Loader />}
                <p style={{marginTop: 32}}>{l.reports.p2}</p>
                <select value={year} onChange={(e) => setYear(e.target.value)} className={classes.select}>
                    {years.map((t) => (
                        <option key={t} value={t}>{t}</option>
                    ))}
                </select>
                {docs.financial ? <FileTable lang={lang} items={docs.financial[year]} /> : <Loader />}
            </Section>
            <Section style={{
                backgroundColor: "#fafafa"
            }} align="left" title={l.donate.t3}>
                {data ? (
                    <>
                    <div className={classes.fundGrid}>
                        {/* <div className={classes.fundblock}>
                            <div className={classes.fundtitle}>{l.donate.endowment}</div>
                            <div className={classes.balEndowment}>{formatMoney(data.endowmentTotal)}₸</div>
                        </div> */}
                        <div className={classes.fundblock}>
                            <div className={classes.fundtitle}>{l.donate.development}</div>
                            <div className={classes.balDev}>{formatMoney(data.development)}₸</div>
                        </div>
                        <div className={classes.fundblock}>
                            <div className={classes.fundtitle}>{l.donate.sponsor}</div>
                            <div className={classes.balSponsor}>{formatMoney(data.sponsor)}₸</div>
                        </div>
                        <div className={classes.fundblock}>
                            <div className={classes.fundtitle}>{l.donate.salary}</div>
                            <div className={classes.balSalary}>{formatMoney(data.salary)}₸</div>
                        </div>
                    </div>   
                    <div>
                        <span className={classes.updated}>{l.donate.updated} {data.updated}</span>
                        <span className={clsx(classes.updated, classes.hidden)}>|</span>
                        <span className={classes.updated}>{l.donate.uploaded} {data.uploaded}</span>
                        <span className={clsx(classes.updated, classes.hidden)}>|</span>
                        <span className={classes.updated}><a className={classes.link} href="/docs/npa/financial_strategy.pdf">{l.donate.strategy}</a></span>
                    </div>     
                              
                    </>
                ) : <Loader />}
            </Section>
            <Section align="left" title={l.reports.t3}>
                <p>{l.reports.mat1} <a className={classes.link} href="https://matomo.org">Matomo</a>{l.reports.mat2}</p>
                    <div className={classes.filter}>
                        <select value={selection} onChange={(e) => setSelection(e.target.value)} className={classes.select}>
                            {selections.map((t) => (
                                <option key={t.value} value={t.value}>{t.name[lang]}</option>
                            ))}
                        </select>
                        {selection === "ask" ? null : <select value={dataType} onChange={(e) => setDataType(e.target.value)} className={classes.select}>
                            {dataTypes.map((t) => (
                                <option key={t.value} value={t.value}>{t.name[lang]}</option>
                            ))}
                        </select>}
                    </div>
                {selection === "ask" ? <Plotly height="520px" fname={`visits/${lang}/${selection}`}/> : <Plotly height="520px" fname={`visits/${lang}/${selection}-${dataType}`}/>}
                <h3>{l.reports.t3}</h3>
                {/* <select value={source} onChange={(e) => setSource(e.target.value)} className={classes.select}>
                    {sources.map((t) => (
                        <option key={t.value} value={t.value}>{t.name}</option>
                    ))}
                </select> */}
                {source === "sources-bar" ? <Plotly height="420px" fname={`sources/${lang}/${source}`}/> : <Plotly height="420px" fname={`sources/${lang}/pie-${source}`}/>}
            </Section>
            <Section style={{
                backgroundColor: "#fafafa"
            }} align="left" title={l.donate.endowstructure}>
                {data ?
                    <>
                        <div className={classes.fundGrid}>
                            <div className={classes.fundblock}>
                                <div className={classes.fundtitle}>{l.donate.endoinv}</div>
                                <div className={classes.endowInvest}>{formatMoney(data.investment)}₸</div>
                            </div>
                            <div className={classes.fundblock}>
                                <div className={classes.fundtitle}>{l.donate.endocur}</div>
                                <div className={classes.endowCur}>{formatMoney(data.endowment)}₸</div>
                            </div>
                        </div>
                    </>
                : <Loader />}
                <p className={classes.title}>{l.reports.t2}</p>
                <select value={curr} onChange={(e) => setCurr(e.target.value)} className={classes.select}>
                    {currencies.map((t) => (
                        <option key={t.value} value={t.value}>{t.name}</option>
                    ))}
                </select>
                <Plotly height="420px" fname={`investments/${lang}/summary-${curr}`}/>
            </Section>
        </div>
    );
};

export default Acts;