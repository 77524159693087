import React from "react";
import classes from "./Loader.module.css";

const Loader = ({ size = 48, style }) => {
  return (
    <div style={style} className={classes.Loader}>
      <svg
        viewBox="0 0 64 64"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path fill="none" d="M0 0h64v64H0z" />
          <path
            d="M32 32h13.5A13.5 13.5 0 1032 18.5v27A13.5 13.5 0 1118.5 32H32z"
            stroke="currentColor"
            strokeWidth="3"
            className={classes.element}
          />
        </g>
      </svg>
    </div>
  );
};

export default Loader;
