import React, { useContext, useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import locale from "../locale.jsx";
import LangContext from "../Utils/Lang";
import Section from "../Components/Section";
import classes from './Documents.module.css';
import FileFormalTable from '../Components/FileFormalTable';
import Loader from '../Components/Loader';
import Axios from 'axios';

const thankTabs = [
    {
        value: "BC"
    },
    {
        value: "SC"
    },
    {
        value: "EX"
    },
    {
        value: "WK"
    }
]

const tabs = [
    {
        ru: "Математика",
        kz: "Математика",
        en: "Math",
        value: "MA"
    },
    {
        ru: "Химия",
        kz: "Химия",
        en: "Chemistry",
        value: "CH"
    },
    {
        ru: "Биология",
        kz: "Биология",
        en: "Biology",
        value: "BI"
    },
    {
        ru: "География",
        kz: "География",
        en: "Geography",
        value: "GE"
    },
    {
        ru: "Физика",
        kz: "Физика",
        en: "Physics",
        value: "PH"
    },
    {
        ru: "CABO",
        kz: "CABO",
        en: "CABO",
        value: "CABO"
    },
    {
        ru: "Хакатон",
        kz: "Хакатон",
        en: "Hackathon",
        value: "HK"
    }
]

const Verify = () => {
    const { trackPageView } = useMatomo()
    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])
    const lang = useContext(LangContext);
    const l = locale[lang];
    const [docs, setDocs] = useState({});
    const [tab, setTab] = useState("MA")
    const [thankTab, setThankTab] = useState("BC")
    useEffect(() => {
        Axios({
            url: "/data/diploma.json",
            params: {
                timestamp: (new Date()) * 1
            }
        }).then((res) => {
            if (res && res.data) {
                setDocs(res.data)
            }
        })
    }, [])
    return (
        <div className={classes.Documents}>
            
            <Section align="left" title={l.diplomas}>
                <select value={tab} onChange={(e) => setTab(e.target.value)} className={classes.select}>
                    {tabs.map((t) => (
                        <option key={t.value} value={t.value}>{t[lang]}</option>
                    ))}
                </select>
                <p>{l.component.legend}: {l.component.hm}</p>
                {docs.diplomas ? <FileFormalTable lang={lang} items={docs.diplomas.filter(el => el.title.indexOf(tab) > -1)} /> : <Loader />}
            </Section>
            <Section align="left" title={l.letters}>
                <select value={thankTab} onChange={(e) => setThankTab(e.target.value)} className={classes.select}>
                    {thankTabs.map((t) => (
                        <option key={t.value} value={t.value}>{t.value}</option>
                    ))}
                </select>
                {docs.letters ? <FileFormalTable lang={lang} items={docs.letters.filter(el => el.title.indexOf(thankTab) > -1)} /> : <Loader />}
            </Section>
        </div>
    );
};

export default Verify;