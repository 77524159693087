import React, { useContext, useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import classes from './Lampa.module.css'
import locale from "../locale.jsx";
import LangContext from "../Utils/Lang";
import Section from '../Components/Section';
import Modal from "../Components/Modal"
import Axios from 'axios';
import clsx from 'clsx';
import Loader from '../Components/Loader';

const Lampa = () => {
    const { trackPageView } = useMatomo()
    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])
    const [data, setData] = useState({
        isFetching: true,
        data: []
    });
    const [modal, setModal] = useState({
        title: "",
        children: "",
        open: false
    })
    const lang = useContext(LangContext);
    const l = locale[lang];
    useEffect(() => {
        Axios({
            method: "GET",
            url: "/data/lampa.json",
            params: {
                timestamp: (new Date()) * 1
            }
        }).then(res => {
            if (res && res.data) {
                setData({
                    isFetching: false,
                    data: res.data
                })
                console.log(res.data)
            }
        });
    }, [])
    const openModal = (member) => (
        setModal({
            title: member.name,
            children: (
                <div className={classes.Member}>
                    <div style={{
                        backgroundImage: `url(${member.img})`
                    }} className={classes.memberPhoto} />
                    <h3>{member.name}</h3>
                    <p>{member.info}</p>
                </div>
            ),
            open: true
        })
    )
    const closeModal = () => (
        setModal({
            ...modal,
            open: false
        })
    )
    return (
        <div className={classes.Lampa}>
            <Section align="left" title={l.lampa.t1}>
                {l.lampa.p1}
            </Section>
            <Section title={l.lampa.t2}>
                {data.isFetching ? <Loader /> : <div className={clsx(classes.members, classes.membersSmall)}>
                    {data.data[lang].map((el, i) => (
                        <Member onClick={openModal} member={el} key={i} />
                    ))}
                </div>}
            </Section>
            <Modal close={closeModal} open={modal.open} title={modal.title}>{modal.children}</Modal>
        </div>
    );

}

const Member = ({ member, subtitle = true, onClick }) => (
    <div className={classes.Member}>
        <div onClick={() => onClick(member)} style={{
            backgroundImage: `url(${member.img})`
        }} className={classes.memberPhoto} />
        <h3>{member.name}</h3>
        <div className={classes.memberActions}>
            <button className={classes.info} onClick={() => onClick(member)}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg></button>
        </div>
    </div>
)

export default Lampa;