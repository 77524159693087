const localeEn = {
    fHead: "Letters from Founders",
    founders: {
        may2021: {
            heading: "Founders letter №1: Let's unite",
            intro: "Dear students and all who share our values!",
            body: [
                "First, we want to congratulate you on the end of the school year! Throughout the last year, we became even more convinced that online education will never replace traditional education. After all, humans are social animals, i.e. we need to see and feel other people. Moreover, the pandemic gave us all a chance to realize how difficult it is to control ourselves and learn something without someone controlling us. Nonetheless, in the days when the labor market changes every year, the ability to learn throughout life becomes so much more important.",
                "Second, we want to celebrate two anniversaries: April 14 marked a year since the foundation of Beyond Curriculum, and June 17 will mark a year from the moment of official registration in Department of Justice. And of course, we want to thank you all for your support — for sharing our materials, for recommending us to your friends, for kind comments, and for your donations. Thanks to you, we are eager to continue, to get better, to do more.",
                "And more needs to be done. For another lesson of the pandemic is that there is no progress in modern society without scientific thinking. Knowing even the basics of science disciplines significantly contributes to the development of logical and critical thinking, which empowers people to discern reliable information in the noise of conspiracy theories and fake news. Moreover, our country needs scientists; we need highly qualified specialists with deep knowledge of STEM. Thus, the issue of popularizing science and additional education is becoming more and more relevant.",
                "All of these means that the activities of Beyond Curriculum PF are becoming and will become even more relevant with every year, if not every month. And here, let me outline our vision of the Foundation: the Beyond Curriculum is not a fixed set of projects that we have created and continue to support. Beyond Curriculum PF is not only about graduates of 2016-2019 who decided to share their knowledge. Beyond Curriculum is an idea, a principle. The principle of striving for the new; the principle of going beyond curriculum.",
                "Hence, we want to appeal to all indifferent and ambitious students of Kazakhstan (and beyond): you can perceive the Beyond Curriculum PF not only as a source of useful materials, but also as a platform through which you can challenge yourself and realize your ideas.",
                "If you want to create a social / non-profit project, you can create it within the Foundation. If you want to create commercial projects, but you have no marketing experience or no understanding of legal practices — you can join our team and try yourself and your ideas in PR or IR. In other words, you have the opportunity to look at the effectiveness of certain strategies without the risk of losing profits. Do you want to try yourself at writing science articles for the general public? Try to write an article for our Blog. Still not sure what you want to do, but want to do something useful? Join our ongoing projects. Are you a beginner web developer looking to expand your portfolio with real projects? We have a lot of tasks that you could take on.",
                "Our current web developer even jokes: we have so many things to do, as if we are an IT company.",
                "We want Beyond Curriculum to become a community in which self-motivated students find like-minded people and start their projects or get their first experience. And with us you can already get experienced in leadership of teams, project management, accounting, web development, PR, writing educational articles, and shooting video lessons.",
                (<>We are looking forward to seeing you, your glowing eyes, and your crazy ideas. You know our <a href="mailto:hr@bc-pf.org">email</a> 🙂</>)
            ],
            confirmed: "Approved: №01-01/07 from May 28, 2021"
        }
    }
}

export default localeEn