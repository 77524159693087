import React from 'react'

const LangContext = React.createContext();

export const LangProvider = LangContext.Provider
export const LangConsumer = LangContext.Consumer

export const langs = ['ru', 'en', 'kz'];

export const langsMap = {ru: 'ru', en: 'en', kk: 'kz'}

export default LangContext