import React, { useContext } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import LangContext from "../Utils/Lang";
import locale from "../locale.jsx";
import classes from './Olympiads.module.css'
import Section from '../Components/Section';

const icons = [
    (
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 24 24" fill="none" stroke="#9C8762" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="arcs">
            <line x1="4" y1="21" x2="4" y2="14" />
            <line x1="4" y1="10" x2="4" y2="3" />
            <line x1="12" y1="21" x2="12" y2="12" />
            <line x1="12" y1="8" x2="12" y2="3" />
            <line x1="20" y1="21" x2="20" y2="16" />
            <line x1="20" y1="12" x2="20" y2="3" />
            <line x1="1" y1="14" x2="7" y2="14" />
            <line x1="9" y1="8" x2="15" y2="8" />
            <line x1="17" y1="16" x2="23" y2="16" />
        </svg>
    ),
    (
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 24 24" fill="none" stroke="#9C8762" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="arcs">
            <circle cx="12" cy="12" r="3" />
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
        </svg>
    ),
    (
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 24 24" fill="none" stroke="#9C8762" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="arcs">
            <circle cx="12" cy="12" r="10" />
            <polyline points="12 6 12 12 16 14" />
        </svg>
    ),
    (
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 24 24" fill="none" stroke="#9C8762" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="arcs">
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
        </svg>
    ),
    (
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 24 24" fill="none" stroke="#9C8762" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="arcs">
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
            <circle cx="12" cy="12" r="3" />
        </svg>
    ),
    (
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 24 24" fill="none" stroke="#9C8762" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="arcs">
            <line x1="12" y1="20" x2="12" y2="10" />
            <line x1="18" y1="20" x2="18" y2="4" />
            <line x1="6" y1="20" x2="6" y2="16" />
        </svg>
    )
];

const Olympiads = () => {
    const { trackPageView } = useMatomo()
    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])
    const lang = useContext(LangContext);
    const l = locale[lang];
    return (
        <div className={classes.Olympiads}>
            <Section align="left" title={l.olympiads.t1}>
                <p>{l.olympiads.p1}</p>
                <div className={classes.timeline}>
                    {l.olympiads.items.map(((el, i) => (
                        <div className={classes.timelineBlock} key={i}>
                            <div className={classes.timelineDate}>{el.dates}</div>
                            <div className={classes.timelineTitle}>{el.title}</div>

                        </div>
                    )))}
                </div>
                <p>{l.olympiads.p2}</p>
            </Section>
            <Section align="left" title={l.olympiads.t3}>
                <p>{l.olympiads.p3}</p>
                <div className={classes.pros}>
                    {l.olympiads.pros.map((el, i) => (
                        <div className={classes.pro}>
                            {icons[i]}
                            <div className={classes.proTitle}>{el.title}</div>
                            <p className={classes.proText}>{el.text}</p>
                        </div>
                    ))}
                </div>
            </Section>
            <Section align="center" title={l.olympiads.t4}>
                <p>{l.olympiads.p4}</p>
            </Section>
        </div>
    );
}

export default Olympiads;