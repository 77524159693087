import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import classes from "./Navbar.module.css";
import LangContext, { langsMap } from "../Utils/Lang";
import locale from "../locale.jsx";
import Container from "./Container";
import Logo from "./Logo";
import { NavLink, Link } from "react-router-dom";
import WidthContext from "../Utils/Width";

const langs = ["ru", "en", "kk"];

/*
const getNext = (arr, el) => {
  const i = arr.indexOf(el);
  return i + 1 === arr.length ? arr[0] : arr[i + 1];
};*/

const Navbar = ({ setLang, setModal }) => {
  const [open, setOpen] = useState(false);
  const [langMenu, setLangMenu] = useState(false)
  const width = useContext(WidthContext);
  const [scrolled, setScrolled] = useState(window.scrollY > 1);
  const lang = useContext(LangContext);
  const l = locale[lang];

  const updateScroll = () => {
    setScrolled(window.scrollY > 1);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, []);

  if (open) {
    document.body.classList.add("scrollDisabled");
  } else {
    document.body.classList.remove("scrollDisabled");
  }

  return (
    <nav
      className={clsx({
        [classes.Navbar]: true,
        [classes.open]: open,
        [classes.scrolled]: scrolled,
      })}
    >
      <Container className={classes.Container}>
        <div className={classes.actions}>
          <div
            onClick={() => {
              setLangMenu(!langMenu);
              //setLang(getNext(langs, lang));
            }}
            className={classes.lang}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 512 512"
            >
              <title>{lang}</title>
              <path d="M267,474l-.8-.13A.85.85,0,0,0,267,474Z" />
              <path d="M448.9,187.78a5.51,5.51,0,0,0-10.67-.63h0A5.52,5.52,0,0,1,433,191H417.53a5.48,5.48,0,0,1-2.84-.79l-22.38-13.42a5.48,5.48,0,0,0-2.84-.79h-35.8a5.48,5.48,0,0,0-3.06.93l-44.15,29.43A5.52,5.52,0,0,0,304,211v41.74a5.51,5.51,0,0,0,2.92,4.87l57.89,30.9a5.55,5.55,0,0,1,2.92,4.8L368,316.8a5.53,5.53,0,0,0,2.85,4.75l23.26,12.87a5.54,5.54,0,0,1,2.85,4.83v48.6a5.52,5.52,0,0,0,9.17,4.14c9.38-8.26,22.83-20.32,24.62-23.08q4.44-6.87,8.33-14.07a207.39,207.39,0,0,0,13.6-31C465.36,287.13,455.34,221.14,448.9,187.78Z" />
              <path d="M286.4,302.8l-61.33-46a4,4,0,0,0-2.4-.8h-29.1a3.78,3.78,0,0,1-2.68-1.11l-13.72-13.72a4,4,0,0,0-2.83-1.17H121.15a3.79,3.79,0,0,1-2.68-6.47l8.42-8.42a3.78,3.78,0,0,1,2.68-1.11h32.37a8,8,0,0,0,7.7-5.83l6.89-24.5a4,4,0,0,1,2-2.47L206,177.06a3.79,3.79,0,0,0,2.05-3.37v-12.5a3.82,3.82,0,0,1,.68-2.17L223.33,138a3.75,3.75,0,0,1,1.78-1.38l20.43-7.67a3.79,3.79,0,0,0,2.46-3.55V114a3.8,3.8,0,0,0-1.69-3.16L225.83,97.22A3.83,3.83,0,0,0,222,97l-27.88,13.94a3.78,3.78,0,0,1-4-.41L176.9,100.08a3.8,3.8,0,0,1,.1-6l10.74-7.91a3.78,3.78,0,0,0-.09-6.16L170.92,68.34a3.78,3.78,0,0,0-4-.22c-6.05,3.31-23.8,13.11-30.1,17.52a209.48,209.48,0,0,0-68.16,80c-1.82,3.76-4.07,7.59-4.29,11.72s-3.46,13.35-4.81,17.08a3.78,3.78,0,0,0,.24,3.1l35.69,65.58a3.74,3.74,0,0,0,1.38,1.44l37.55,22.54a3.78,3.78,0,0,1,1.81,2.73l7.52,54.54a3.82,3.82,0,0,0,1.61,2.61l29.3,20.14a4,4,0,0,1,1.65,2.48l15.54,73.8a3.6,3.6,0,0,0,.49,1.22c1.46,2.36,7.28,11,14.3,12.28-.65.18-1.23.59-1.88.78a47.63,47.63,0,0,1,5,1.16c2,.54,4,1,6,1.43,3.13.62,3.44,1.1,4.94-1.68,2-3.72,4.29-5,6-5.46a3.85,3.85,0,0,0,2.89-2.9l10.07-46.68a4,4,0,0,1,1.6-2.42l45-31.9a4,4,0,0,0,1.69-3.27V306A4,4,0,0,0,286.4,302.8Z" />
              <path d="M262,48s-3.65.21-4.39.23q-8.13.24-16.22,1.12A207.45,207.45,0,0,0,184.21,64c2.43,1.68-1.75,3.22-1.75,3.22L189,80h35l24,12,21-12Z" />
              <path d="M354.23,120.06l16.11-14a4,4,0,0,0-.94-6.65l-18.81-8.73a4,4,0,0,0-5.3,1.9l-7.75,16.21a4,4,0,0,0,1.49,5.11l10.46,6.54A4,4,0,0,0,354.23,120.06Z" />
              <path d="M429.64,140.67l-5.83-9c-.09-.14-.17-.28-.25-.43-1.05-2.15-9.74-19.7-17-26.51-5.45-5.15-7-3.67-7.43-2.53a3.77,3.77,0,0,1-1.19,1.6L369.1,127.11a4,4,0,0,1-2.51.89H351.66a4,4,0,0,0-2.83,1.17l-12,12a4,4,0,0,0,0,5.66l12,12a4,4,0,0,0,2.83,1.17h75.17a4,4,0,0,0,4-4.17l-.55-13.15A4,4,0,0,0,429.64,140.67Z" />
              <path d="M256,72a184,184,0,1,1-130.1,53.9A182.77,182.77,0,0,1,256,72m0-40C132.3,32,32,132.3,32,256S132.3,480,256,480,480,379.7,480,256,379.7,32,256,32Z" />
            </svg>
            <div className={clsx({
              [classes.langMenu]: true,
              [classes.langMenuActive]: langMenu
            })}>
              {langs.map((el, i) => (
                <div key={i} onClick={e => {
                  e.stopPropagation()
                  setLang(langsMap[el]);
                  setLangMenu(false)
                }} className={clsx({
                  [classes.langMenuItem]: true,
                  [classes.langMenuItemSelected]: lang === langsMap[el]
                })}>{el}</div>
              ))}
            </div>
          </div>
          <div onClick={() => setModal(true)} className={classes.subscribe}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path></svg>
          </div>
        </div>
        <Link onClick={() => window.scrollTo(0, 0)} to="/"><Logo className={classes.brand} size={width > 768 ? (scrolled ? 40 : 64) : 40} animated /></Link>
        <div className={classes.burgerWrap}>
          <div onClick={() => setOpen(!open)} className={classes.burger}>
            <div />
            <div />
            <div />
          </div>
        </div>
      </Container>
      <div className={classes.menuWrapper}>
        <div onClick={() => setOpen(false)} className={classes.backdrop} />
        <div
          style={{
            width: width - 1072 > 0 ? (width - 1072) / 2 + 320 : 320,
          }}
          className={classes.menu}
        >
          <div className={classes.menuInner}>
            <Logo className={classes.Logo} size={40} />
            <div onClick={() => setOpen(false)} className={classes.menuItems}>
              <div className={classes.menuItemsInner}>
                <NavLink
                  to="/"
                  exact
                  activeClassName={classes.menuItemActive}
                  className={classes.menuItem}
                >
                  <span>{l.menu.home}</span>
                </NavLink>
                <NavLink
                  to="/about"
                  exact
                  activeClassName={classes.menuItemActive}
                  className={classes.menuItem}
                >
                  <span>{l.menu.about}</span>
                </NavLink>
                <NavLink
                  to="/members"
                  exact
                  activeClassName={classes.menuItemActive}
                  className={classes.menuItem}
                >
                  <span>{l.menu.team}</span>
                </NavLink>
                <NavLink
                  to="/jobs"
                  exact
                  activeClassName={classes.menuItemActive}
                  className={classes.menuItem}
                >
                  <span>{l.jobs.title}</span>
                </NavLink>
                <NavLink
                  to="/olympiads"
                  exact
                  activeClassName={classes.menuItemActive}
                  className={classes.menuItem}
                >
                  <span>{l.menu.olympiads}</span>
                </NavLink>
                <Accordion title={l.menu.projects}>
                  {/* <NavLink
                    to="/communities"
                    exact
                    activeClassName={classes.menuItemActive}
                    className={classes.menuItem}
                  >
                    <span>{l.menu.communities}</span>
                    <span className={classes.menuItemText}>
                      {l.menu.communitiesText}
                    </span>
                  </NavLink> */}
                  {/* <a
                    className={clsx(classes.menuItem, classes.external)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://fest.bc-pf.org/"
                  >
                    <span>Beyond Fest</span>
                    <span className={classes.menuItemText}>
                      {l.menu.festText}
                    </span>
                  </a> */}
                  <a
                    className={clsx(classes.menuItem, classes.external)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ask.bc-pf.org/"
                  >
                    <span>{l.menu.ask}</span>
                    <span className={classes.menuItemText}>
                      {l.menu.askText}
                    </span>
                  </a>
                  <a
                    className={clsx(classes.menuItem, classes.external)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://olympiads.bc-pf.org/"
                  >
                    <span>{l.menu.olympiads}</span>
                    <span className={classes.menuItemText}>
                      {l.menu.olympiadsText}
                    </span>
                  </a>
                  <a
                    className={clsx(classes.menuItem, classes.external)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://info.bc-pf.org"
                  >
                    <span>{l.menu.olympinfo}</span>
                    <span className={classes.menuItemText}>
                      {l.menu.olympinfoText}
                    </span>
                  </a>
                  <a
                    className={clsx(classes.menuItem, classes.external)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://scoreboard.bc-pf.org/"
                  >
                    <span>Scoreboard</span>
                    <span className={classes.menuItemText}>
                      {l.menu.scoreboard}
                    </span>
                  </a>
                  <a
                    className={clsx(classes.menuItem, classes.external)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={l.menu.blogHref}
                  >
                    <span>{l.menu.blog}</span>
                    <span className={classes.menuItemText}>
                      {l.menu.blogText}
                    </span>
                  </a>
                  <a
                    href="https://robotics.bc-pf.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={clsx(classes.menuItem, classes.external)}
                  >
                  <span>{l.menu.robotics}</span>
                  <span className={classes.menuItemText}>
                      {l.menu.roboticsText}
                  </span>
                  </a>
                  <a
                    href="https://school.bc-pf.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={clsx(classes.menuItem, classes.external)}
                  >
                    <span>{l.menu.school}</span>
                    <span className={classes.menuItemText}>
                      {l.menu.schoolText}
                    </span>
                  </a>
                  <a
                    className={clsx(classes.menuItem, classes.external)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://wiki.bc-pf.org/"
                  >
                    <span>Wiki</span>
                    <span className={classes.menuItemText}>
                      {l.menu.wikiText}
                    </span>
                  </a>
                  <a
                    className={clsx(classes.menuItem, classes.external)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://design.bc-pf.org"
                  >
                    <span>{l.menu.design}</span>
                    <span className={classes.menuItemText}>
                      {l.menu.designText}
                    </span>
                  </a>
                  <NavLink
                    to="/lampa"
                    exact
                    activeClassName={classes.menuItemActive}
                    className={classes.menuItem}
                  >
                    <span>{l.menu.lampa}</span>
                  </NavLink>
                  <a
                    className={clsx(classes.menuItem, classes.external)}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://t.me/kazolympbot"
                  >
                    <span>KazOlympBot</span>
                    <span className={classes.menuItemText}>
                      {l.menu.botText}
                    </span>
                  </a>
                </Accordion>
                <NavLink
                  to="/donate"
                  exact
                  activeClassName={classes.menuItemActive}
                  className={classes.menuItem}
                >
                  <span>{l.menu.donate}</span>
                </NavLink>
                <NavLink
                  to="/documents"
                  exact
                  activeClassName={classes.menuItemActive}
                  className={classes.menuItem}
                >
                  <span>{l.docs}</span>
                </NavLink>
                <NavLink
                  to="/reports"
                  exact
                  activeClassName={classes.menuItemActive}
                  className={classes.menuItem}
                >
                  <span>{l.reports.t1}</span>
                </NavLink>
                <NavLink
                  to="/letters"
                  exact
                  activeClassName={classes.menuItemActive}
                  className={classes.menuItem}
                >
                  <span>{l.lettersPage}</span>
                </NavLink>
                <NavLink
                  to="/verify"
                  exact
                  activeClassName={classes.menuItemActive}
                  className={classes.menuItem}
                >
                  <span>{l.menu.verify}</span>
                </NavLink>
                <NavLink
                  to="/contacts"
                  exact
                  activeClassName={classes.menuItemActive}
                  className={classes.menuItem}
                >
                  <span>{l.menu.contacts}</span>
                </NavLink>
              </div>
            </div>
            <div className={classes.socialLinks}>
              <a
                href="https://t.me/bcedukz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <title>Telegram</title>
                  <path d="M23.91 3.79L20.3 20.84c-.25 1.21-.98 1.5-2 .94l-5.5-4.07-2.66 2.57c-.3.3-.55.56-1.1.56-.72 0-.6-.27-.84-.95L6.3 13.7l-5.45-1.7c-1.18-.35-1.19-1.16.26-1.75l21.26-8.2c.97-.43 1.9.24 1.53 1.73z" />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/beyondcurriculum/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Instagram</title>
                  <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/c/BeyondCurriculum"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <title>YouTube</title>
                  <path d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/bcedukz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <title>Facebook</title>
                <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Facebook icon</title><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
              </a>
              <a
                href="https://vk.com/beyondcurriculum"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>VK</title>
                  <path d="M11.701 18.771h1.437s.433-.047.654-.284c.21-.221.21-.63.21-.63s-.031-1.927.869-2.21c.887-.281 2.012 1.86 3.211 2.683.916.629 1.605.494 1.605.494l3.211-.044s1.682-.105.887-1.426c-.061-.105-.451-.975-2.371-2.76-2.012-1.861-1.742-1.561.676-4.787 1.469-1.965 2.07-3.166 1.875-3.676-.166-.48-1.26-.361-1.26-.361l-3.602.031s-.27-.031-.465.09c-.195.119-.314.391-.314.391s-.572 1.529-1.336 2.82c-1.623 2.729-2.268 2.879-2.523 2.699-.604-.391-.449-1.58-.449-2.432 0-2.641.404-3.75-.781-4.035-.39-.091-.681-.15-1.685-.166-1.29-.014-2.378.01-2.995.311-.405.203-.72.652-.539.675.24.03.779.146 1.064.537.375.506.359 1.636.359 1.636s.211 3.116-.494 3.503c-.495.262-1.155-.28-2.595-2.756-.735-1.26-1.291-2.67-1.291-2.67s-.105-.256-.299-.406c-.227-.165-.557-.225-.557-.225l-3.435.03s-.51.016-.689.24c-.166.195-.016.615-.016.615s2.686 6.287 5.732 9.453c2.79 2.902 5.956 2.715 5.956 2.715l-.05-.055z" />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/37480729/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <title>LinkedIn</title>
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const Accordion = ({ children, title = "" }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={classes.Accordion}>
      <div onClick={(e) => {
        e.stopPropagation()
        setOpen(!open)
      }} className={classes.AccordionTitle}>
        <span>{title}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">{!open ? <path d="M6 9l6 6 6-6" /> : <path d="M18 15l-6-6-6 6" />}</svg>
      </div>
      {open ? <div className={classes.AccordionContent}>
        {children}
      </div> : null}
    </div>
  )
}

export default Navbar;