import React, { useContext, useState, useEffect } from "react";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import classes from "./About.module.css";
import Section from "../Components/Section";
import locale from "../locale.jsx";
import LangContext from "../Utils/Lang";
import Axios from "axios";
import Loader from "../Components/Loader";
import ustav from "../Assets/ustav.jpg"
import ustavEn from "../Assets/ustav_en.jpg"

const About = () => {
  const { trackPageView } = useMatomo()
  // Track page view
  React.useEffect(() => {
    trackPageView()
  }, [])
  const [timeline, setTimeline] = useState({
    isFetching: true,
    data: {}
  })
  const lang = useContext(LangContext);
  const l = locale[lang];
  useEffect(() => {
    Axios({
      url: "/data/timeline.json",
      params: {
        timestamp: (new Date()) * 1
      }
    }).then((res) => {
      if (res.data) {
        setTimeline({
          isFetching: false,
          data: res.data
        })
      }
    })
  }, [])
  return (
    <div className={classes.About}>
      <Section big title={l.about.t1}>
        <p>{l.about.p1}</p>
      </Section>
      <Section align="left" container={false} title={l.about.t2}>
        <Slider autoplay autoplaySpeed={0} easing="linear" slidesToShow={l.about.p2.length - 1} infinite arrows={false}>
          {l.about.p2.map((el, i) => (
            <div className={classes.values} key={i}>{el}</div>
          ))}
        </Slider>
      </Section>
      <Section style={{
        backgroundColor: "#fafafa"
      }} align="left" title={l.about.t3}>
        <ul>
          {l.about.p3.map((el, i) => (
            <li key={i}>{el}</li>
          ))}
        </ul>
      </Section>
      <Section align="left" title={l.about.t4}>
        <div className={classes.flex}>
          <p>{l.about.p4}</p>
          <a target="_blank" rel="noopener noreferrer" href={lang === "en" ? "/docs/ustav_en_redacted.pdf" : "/docs/ustav_redacted.pdf"} className={classes.ustav}>
            <div className={classes.label}>pdf</div>
            <img src={lang === "en" ? ustavEn : ustav} alt={l.about.t4} />
          </a>
        </div>
      </Section>
      <Section style={{
        backgroundColor: "#fafafa"
      }} align="left" title={l.about.t5}>
        <div>{l.about.p5}</div>
      </Section>
      <Section align="left" title={l.about.t6}>
        {timeline.isFetching ? <Loader /> : (
          <div className={classes.timeline}>
            {timeline.data[lang].map((el, i) => (
              <div key={i} className={classes.timeBlock}>
                <div className={classes.timeDate}>{el.date}</div>
                <div className={classes.timeText}>{el.text}</div>
              </div>
            ))}
          </div>
        )}
      </Section>
    </div>
  );
};


const Slider = ({ children }) => {
  /*
  const [x, setX] = useState(-33.333);
  const updateX = () => {
    if (x < -66.6666) {
      setX(-33.3333)
    }
    setX(x - 1);
  }
  useEffect(() => {
    const timer = setInterval(updateX, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [updateX])*/

  return (
    <div className={classes.Slider}>
      <div style={{
        //transform: `translateX(${x}%)`
      }} className={classes.SliderInner}>
        {children}
        {children}
        {children}
      </div>
    </div>
  )
}

export default About;
